/* Daily | Weekly | Monthly */
.cc-dates-nav {
  @apply relative z-0 rounded-lg shadow flex divide-x divide-quaternary;
}

/* Sales Management body page header */
.sales-header {
  @apply text-lg font-medium leading-5 text-secondary px-6 mt-6;
}

/* Sales Management body Vendor status card 1 */
.cc-status-box {
  @apply relative h-20 bg-custWhite px-4  sm:px-6 shadow rounded-lg overflow-hidden cursor-pointer;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}

/* Sales Management body Vendor status card 1 icon */
.cc-sales-box-icon {
  @apply absolute bg-primary  rounded-full p-3 mt-1.5;
}

/* Sales Management body Vendor status card 1 arrow */
.cc-sales-redirect-arrow {
  @apply h-5 w-5  cursor-pointer  absolute right-4 top-8;
}

.cc-sales-card2 {
  @apply box-border md:h-24 h-32 w-full px-6 py-5 bg-custWhite;
  border: 1px solid #e8ebf1;
  border-radius: 5px;
}

.cc-sales-card-header {
  @apply text-2xl font-semibold text-secondary;
}

.cc-sales-card-subheader {
  @apply text-xs font-medium text-ternary;
}

/* Sales Management body Vendor status card 3 */
.cc-status-box3 {
  @apply h-20 bg-custWhite px-4 w-full sm:px-6 shadow rounded-lg overflow-hidden;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}

.cc-sales-box-icon-revert {
  @apply absolute bg-e6e9f0 rounded-full p-3 mt-1.5;
}

.cc-theme-shadow {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
}
