.category-badge {
  @apply inline-flex items-center px-2.5 py-0.5 mr-1 rounded-full text-xs font-medium text-secondary cursor-pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip .tooltiptextleft {
  visibility: hidden;
  /* width: 120px; */
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 4px !important;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip .tooltiptextleft1 {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 4px !important;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  /* right: 30px; */
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip:hover .tooltiptextleft {
  visibility: visible;
}

.tooltip:hover .tooltiptextleft1 {
  visibility: visible;
}
